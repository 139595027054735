.service1 {
  color: #000;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-image: url("../../../images/background/background2.png");
}
@media screen and (max-width: 959px) {
  .service1 {
    font-size: 14px;
  }
}
.service1 .aide {
  position: fixed;
  bottom: 15%;
  right: 5%;
  z-index: 9999;
  z-index: 9999;
}
.service1 .aide .content {
  border-radius: 142.5px;
  background: #F00;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 0.5rem 0 0rem !important;
  margin-left: auto;
}
@media screen and (max-width: 959px) {
  .service1 .aide .content {
    padding: 0 !important;
  }
}
@media screen and (max-width: 959px) {
  .service1 .aide .content {
    padding: 0 !important;
  }
}
.service1 .aide .content .white-round {
  width: 76px;
  height: 76px;
  background-color: #FFF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 4px;
}
.service1 .aide .content .text-content {
  display: flex;
  flex-direction: column;
  color: #FFF;
  margin-left: 20px;
}
.service1 .aide .content .text-content h3 {
  font-size: 18px;
  font-weight: 700;
  height: 21px;
  margin: 0;
  padding: 0;
}
.service1 .aide .content .text-content span {
  font-weight: 400;
  font-size: 14px;
  height: 21px;
  margin-top: 7px;
}
.service1 .service-description {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-left: 60px;
  margin-bottom: 58px;
  padding-top: 68px;
}
@media screen and (max-width: 959px) {
  .service1 .service-description {
    width: 95%;
    margin: 0 auto;
  }
}
.service1 .service-description h1 {
  font-size: 38px;
  font-weight: 600;
}
@media screen and (max-width: 959px) {
  .service1 .service-description h1 {
    font-size: 28px;
    font-weight: 600;
  }
}
.service1 .service-description span {
  color: #f00;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 13px;
}
@media screen and (max-width: 959px) {
  .service1 .service-description span {
    font-size: 15px;
    font-weight: 600;
  }
}
.service1 .service-description p {
  width: 70%;
  font-size: 14px;
  font-weight: 400;
}
@media screen and (max-width: 959px) {
  .service1 .service-description p {
    font-size: 12px;
    width: 100%;
  }
}
.service1 .style1 {
  display: flex;
  align-items: center;
  width: 100%;
  background: #F4F4F4;
}
@media screen and (max-width: 959px) {
  .service1 .style1 {
    flex-direction: column;
    margin: 1rem 0;
  }
}
.service1 .style1 .card-left {
  display: flex;
  align-items: center;
  border-radius: 159.5px;
  width: 60%;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  background: #F00;
  color: #fff;
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.07);
  height: 90px;
}
@media screen and (max-width: 959px) {
  .service1 .style1 .card-left {
    width: 100%;
  }
}
.service1 .style1 .card-left h2 {
  font-weight: 700;
  font-size: 28px;
  padding-left: 100px;
}
@media screen and (max-width: 959px) {
  .service1 .style1 .card-left h2 {
    font-size: 18px;
    padding-left: 20px;
  }
}
.service1 .style1 .card-left img {
  margin-left: auto;
  margin-right: 5px;
}
.service1 .style1 .avantage-description {
  width: 32%;
  display: flex;
  margin-left: auto;
  margin-right: 70px;
  margin-top: 66px;
  margin-bottom: 66px;
}
@media screen and (max-width: 959px) {
  .service1 .style1 .avantage-description {
    gap: 5px;
    width: 95%;
    margin: 1rem auto;
  }
}
.service1 .style1 .avantage-description div {
  margin-right: 20px;
}
@media screen and (max-width: 959px) {
  .service1 .style1 .avantage-description div {
    margin: 0;
  }
}
.service1 .style2 {
  display: flex;
  align-items: center;
  width: 100%;
}
@media screen and (max-width: 959px) {
  .service1 .style2 {
    flex-direction: column-reverse;
    margin: 1rem 0;
  }
}
.service1 .style2 .card-right {
  display: flex;
  align-items: center;
  border-radius: 159.5px;
  width: 60%;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  background: #F00;
  color: #fff;
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.07);
  height: 90px;
}
@media screen and (max-width: 959px) {
  .service1 .style2 .card-right {
    width: 100%;
  }
}
.service1 .style2 .card-right h2 {
  font-weight: 700;
  font-size: 28px;
  margin: 0 auto;
}
@media screen and (max-width: 959px) {
  .service1 .style2 .card-right h2 {
    font-size: 18px;
    padding-left: 20px;
  }
}
.service1 .style2 .card-right img {
  margin-left: 5px;
  width: 81px;
  height: 81px;
}
.service1 .style2 .avantage-description {
  width: 30%;
  display: flex;
  margin-left: auto;
  margin-right: 70px;
  margin-top: 66px;
  margin-bottom: 66px;
}
@media screen and (max-width: 959px) {
  .service1 .style2 .avantage-description {
    gap: 5px;
    width: 95%;
    margin: 1rem auto;
  }
}
.service1 .style2 .avantage-description div {
  margin-right: 20px;
}
@media screen and (max-width: 959px) {
  .service1 .style2 .avantage-description div {
    margin: 0;
  }
}
.service1 .outro {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 77px;
  padding-bottom: 46px;
  background-color: #FFF;
}
@media screen and (max-width: 959px) {
  .service1 .outro {
    flex-direction: column;
    margin: 1rem 0;
  }
}
.service1 .outro .outro-text {
  width: 30%;
  margin-left: 97px;
}
@media screen and (max-width: 959px) {
  .service1 .outro .outro-text {
    width: 95%;
    margin: 0 auto;
  }
}
.service1 .outro .outro-text h3 {
  font-size: 18px;
  font-weight: 500;
  color: #F00;
}
.service1 .outro .outro-text span {
  font-size: 15px;
  font-weight: 400;
}
.service1 .outro .outro-style {
  margin-left: auto;
  width: 60%;
  margin-top: 50px;
}
@media screen and (max-width: 959px) {
  .service1 .outro .outro-style {
    width: 100%;
  }
}
.service1 .outro .outro-style .services #service1 {
  color: #fff;
  background-color: #F00;
  width: 100%;
  margin-left: 0;
}
.service1 .outro .outro-style .services .service {
  border-radius: 142.5px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  margin-left: 60px;
  margin-bottom: 27px;
}
.service1 .outro .outro-style .services .service .tick {
  position: relative;
  display: flex;
  height: 32px;
  margin-left: 4px;
  margin-top: 5px;
  margin-bottom: 4px;
}
.service1 .outro .outro-style .services .service .tick svg {
  stroke-width: 2px;
  stroke: #FFF;
  flex-shrink: 0;
}
.service1 .outro .outro-style .services .service .tick .container-overlay {
  position: absolute;
  display: flex;
  top: 0;
}
.service1 .outro .outro-style .services .service .tick .container-overlay svg {
  flex-shrink: 0;
  padding-left: 6px;
  padding-top: 6px;
}
.service1 .outro .outro-style .services .service span {
  font-size: 14px;
  font-weight: 500;
  margin-left: 30px;
  color: #000;
}
@media screen and (max-width: 959px) {
  .service1 .outro .outro-style .services .service span {
    margin-left: 5px;
    font-size: 12px;
  }
}