.service2 {
  color: #000;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-image: url("../../../images/background/background2.png");
}
.service2 .service-header {
  display: flex;
  align-items: center;
  padding-bottom: 26.5px;
  padding-top: 68px;
}
.service2 .service-header .service-description {
  display: flex;
  flex-direction: column;
  width: 52.5%;
  margin-left: 60px;
}
@media screen and (max-width: 959px) {
  .service2 .service-header .service-description {
    width: 95%;
    margin: 0 auto;
    flex-direction: column;
  }
}
.service2 .service-header .service-description h1 {
  font-size: 38px;
  font-weight: 600;
}
@media screen and (max-width: 959px) {
  .service2 .service-header .service-description h1 {
    font-size: 28px;
    font-weight: 600;
  }
}
.service2 .service-header .service-description span {
  color: #f00;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 13px;
}
.service2 .service-header .service-description p {
  width: 70%;
  font-size: 14px;
  font-weight: 400;
  margin-block-end: 0;
}
@media screen and (max-width: 959px) {
  .service2 .service-header .service-description p {
    width: 100%;
  }
}
.service2 .service-header .aide {
  position: fixed;
  bottom: 15%;
  right: 5%;
  z-index: 9999;
}
.service2 .service-header .aide .content {
  border-radius: 142.5px;
  background: #F00;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 0.5rem 0 0rem !important;
  margin-left: auto;
}
@media screen and (max-width: 959px) {
  .service2 .service-header .aide .content {
    padding: 0 !important;
  }
}
.service2 .service-header .aide .content .white-round {
  width: 76px;
  height: 76px;
  background-color: #FFF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 4px;
}
.service2 .service-header .aide .content .text-content {
  display: flex;
  flex-direction: column;
  color: #FFF;
  margin-left: 20px;
}
.service2 .service-header .aide .content .text-content h3 {
  font-size: 18px;
  font-weight: 700;
  height: 21px;
  margin: 0;
  padding: 0;
}
.service2 .service-header .aide .content .text-content span {
  font-weight: 400;
  font-size: 14px;
  height: 21px;
  margin-top: 7px;
}
.service2 .gestion-technique {
  display: flex;
  align-items: center;
  margin-left: auto;
  background-color: #F1F1F1;
  padding-top: 30.5px;
  padding-bottom: 26px;
}
@media screen and (max-width: 959px) {
  .service2 .gestion-technique {
    flex-direction: column;
  }
}
.service2 .gestion-technique .gestion-technique-card {
  display: flex;
  background-color: #FFF;
  border-radius: 159.5px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.07);
  width: 40%;
  padding: 5px 0px;
  margin-right: 60px;
}
@media screen and (max-width: 959px) {
  .service2 .gestion-technique .gestion-technique-card {
    width: 100%;
  }
}
.service2 .gestion-technique .gestion-technique-card img {
  margin-left: auto;
  margin-right: 20px;
}
.service2 .gestion-technique .gestion-technique-text {
  display: flex;
  flex-direction: column;
  width: 65%;
}
@media screen and (max-width: 959px) {
  .service2 .gestion-technique .gestion-technique-text {
    width: 95%;
    margin: 0 auto;
  }
}
.service2 .gestion-technique .gestion-technique-text h3 {
  color: #F00;
  font-size: 18px;
  font-weight: 500;
}
@media screen and (max-width: 959px) {
  .service2 .gestion-technique .gestion-technique-text h3 {
    font-size: 16px;
  }
}
.service2 .gestion-technique .gestion-technique-text .content {
  display: flex;
  gap: 5px;
}
.service2 .gestion-technique .gestion-technique-text .content div {
  margin-right: 30px;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 959px) {
  .service2 .gestion-technique .gestion-technique-text .content div {
    margin: 0;
    gap: 5px;
  }
}
.service2 .gestion-technique .gestion-technique-text .content div span {
  padding-bottom: 20px;
  font-size: 17px;
  font-weight: 400;
  width: 90%;
}
@media screen and (max-width: 959px) {
  .service2 .gestion-technique .gestion-technique-text .content div span {
    width: 100%;
    font-size: 14px;
  }
}
.service2 .gestion-technique .gestion-technique-text .content div span:last-child {
  width: 85%;
}
@media screen and (max-width: 959px) {
  .service2 .gestion-technique .gestion-technique-text .content div span:last-child {
    width: 100%;
    font-size: 14px;
  }
}
.service2 .gestion-biens {
  display: flex;
  align-items: center;
  margin-left: auto;
  background-color: #FFF;
  padding-top: 30.5px;
  padding-bottom: 26px;
}
@media screen and (max-width: 959px) {
  .service2 .gestion-biens {
    flex-direction: column;
  }
}
.service2 .gestion-biens .gestion-biens-card {
  display: flex;
  background-color: #FFF;
  border-radius: 159.5px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.07);
  width: 40%;
  padding: 5px 0px;
  margin-right: auto;
}
@media screen and (max-width: 959px) {
  .service2 .gestion-biens .gestion-biens-card {
    width: 100%;
  }
}
.service2 .gestion-biens .gestion-biens-card img {
  margin-right: auto;
  margin-left: 20px;
}
.service2 .gestion-biens .gestion-biens-text {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-right: 30px;
  margin-left: 60px;
}
@media screen and (max-width: 959px) {
  .service2 .gestion-biens .gestion-biens-text {
    width: 95%;
    margin: 0 auto;
  }
}
.service2 .gestion-biens .gestion-biens-text h3 {
  color: #F00;
  font-size: 18px;
  font-weight: 500;
}
@media screen and (max-width: 959px) {
  .service2 .gestion-biens .gestion-biens-text h3 {
    font-size: 16px;
  }
}
.service2 .gestion-biens .gestion-biens-text .content {
  display: flex;
  gap: 5px;
}
.service2 .gestion-biens .gestion-biens-text .content div {
  margin-right: 26px;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 959px) {
  .service2 .gestion-biens .gestion-biens-text .content div {
    margin: 0;
    gap: 5px;
  }
}
.service2 .gestion-biens .gestion-biens-text .content div span {
  padding-bottom: 20px;
  font-size: 17px;
  font-weight: 400;
  width: 90%;
}
@media screen and (max-width: 959px) {
  .service2 .gestion-biens .gestion-biens-text .content div span {
    width: 100%;
    font-size: 14px;
  }
}
.service2 .gestion-biens .gestion-biens-text .content div span:nth-child(2) {
  width: 90%;
}
@media screen and (max-width: 959px) {
  .service2 .gestion-biens .gestion-biens-text .content div span:nth-child(2) {
    width: 100%;
    font-size: 14px;
  }
}
.service2 .gestion-biens .gestion-biens-text .content div span:last-child {
  width: 90%;
}
@media screen and (max-width: 959px) {
  .service2 .gestion-biens .gestion-biens-text .content div span:last-child {
    width: 100%;
    font-size: 14px;
  }
}
.service2 .outro {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  margin-right: 60px;
  margin-top: 100px;
  margin-bottom: 105px;
}
@media screen and (max-width: 959px) {
  .service2 .outro {
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 95%;
  }
}
.service2 .outro h1 {
  font-weight: 600;
  font-size: 38px;
  line-height: 57px;
  width: 65%;
  margin-bottom: 4rem;
}
@media screen and (max-width: 959px) {
  .service2 .outro h1 {
    width: 100%;
    font-size: 24px;
    line-height: 150%;
  }
}
.service2 .outro .outro-left {
  display: flex;
  align-items: center;
  max-width: 55%;
  background-color: #F1F1F1;
  border-radius: 159.5px;
  margin-right: auto;
  height: auto;
  margin-bottom: 68px;
  padding: 2rem;
  gap: 25px;
}
@media screen and (max-width: 959px) {
  .service2 .outro .outro-left {
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    align-items: flex-start;
    padding: 1.5rem 0;
    margin: 1rem 0 !important;
    border-radius: 8px;
  }
}
.service2 .outro .outro-left .loop {
  position: relative;
  display: flex;
  margin-left: 4px;
  margin-top: 5px;
  margin-bottom: 4px;
}
@media screen and (max-width: 959px) {
  .service2 .outro .outro-left .loop {
    width: 90%;
    margin: 0 auto;
  }
}
.service2 .outro .outro-left .loop img {
  stroke-width: 2px;
  stroke: #FFF;
  flex-shrink: 0;
}
.service2 .outro .outro-left .loop .container-overlay {
  position: absolute;
  display: flex;
  top: 0;
}
.service2 .outro .outro-left .loop .container-overlay img {
  flex-shrink: 0;
  padding-left: 10px;
  padding-top: 10px;
}
@media screen and (max-width: 959px) {
  .service2 .outro .outro-left img {
    margin-left: 1rem;
  }
}
@media screen and (max-width: 959px) {
  .service2 .outro .outro-left div {
    width: 90%;
    margin: 0 auto;
  }
}
.service2 .outro .outro-left div h3 {
  font-weight: 500;
  font-size: 18px;
  color: #F00;
  margin: 0;
  margin-bottom: 0.5rem;
}
@media screen and (max-width: 959px) {
  .service2 .outro .outro-left div h3 {
    font-size: 15px;
  }
}
.service2 .outro .outro-left div div {
  width: 100%;
}
.service2 .outro .outro-left div div span {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}
@media screen and (max-width: 959px) {
  .service2 .outro .outro-left div div span {
    font-size: 12px;
  }
}
.service2 .outro .outro-right {
  display: flex;
  align-items: center;
  max-width: 55%;
  background-color: #F1F1F1;
  border-radius: 159.5px;
  margin-left: auto;
  height: auto;
  margin-bottom: 68px;
  padding: 2rem;
  gap: 25px;
}
@media screen and (max-width: 959px) {
  .service2 .outro .outro-right {
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    align-items: flex-start;
    padding: 1.5rem 0;
    margin: 1rem 0 !important;
    border-radius: 8px;
  }
}
.service2 .outro .outro-right .judge {
  position: relative;
  display: flex;
  margin-left: 4px;
  margin-top: 5px;
  margin-bottom: 4px;
  padding-bottom: 23px;
}
.service2 .outro .outro-right .judge img {
  stroke-width: 2px;
  stroke: #FFF;
  flex-shrink: 0;
  padding-left: 30px;
}
.service2 .outro .outro-right .judge .container-overlay {
  position: absolute;
  display: flex;
  top: 0;
}
.service2 .outro .outro-right .judge .container-overlay img {
  flex-shrink: 0;
  padding-left: 10px;
  padding-top: 23px;
}
@media screen and (max-width: 959px) {
  .service2 .outro .outro-right .judge .container-overlay img {
    padding-left: 0;
  }
}
@media screen and (max-width: 959px) {
  .service2 .outro .outro-right img {
    margin-left: 1rem;
  }
}
@media screen and (max-width: 959px) {
  .service2 .outro .outro-right div {
    width: 90%;
    margin: 0 auto;
  }
}
.service2 .outro .outro-right div h3 {
  font-weight: 500;
  font-size: 18px;
  color: #F00;
  margin: 0;
  margin-bottom: 0.5rem;
}
@media screen and (max-width: 959px) {
  .service2 .outro .outro-right div h3 {
    font-size: 15px;
  }
}
.service2 .outro .outro-right div div {
  width: 100%;
}
.service2 .outro .outro-right div div span {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}
@media screen and (max-width: 959px) {
  .service2 .outro .outro-right div div span {
    font-size: 12px;
  }
}