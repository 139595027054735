.home {
  color: black;
  display: block;
  margin: 53px 25px 0px 61px;
  font-weight: 300;
  font-style: normal;
  line-height: normal;
  font-family: Poppins;
}
@media screen and (max-width: 959px) {
  .home {
    margin: 0;
  }
}
.home .description-section {
  display: flex;
}
@media screen and (max-width: 959px) {
  .home .description-section {
    flex-direction: column;
    margin: 0 auto;
    width: 95%;
  }
}
.home .description-section .description {
  width: 70%;
  color: #000;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
}
@media screen and (max-width: 959px) {
  .home .description-section .description {
    width: 100%;
  }
}
.home .description-section .description h1 {
  font-size: 38px;
  font-weight: 600;
}
@media screen and (max-width: 959px) {
  .home .description-section .description h1 {
    margin-top: 1.5rem;
    font-size: 28px;
  }
}
.home .description-section .description div {
  margin-top: 48px;
  width: 65%;
}
@media screen and (max-width: 959px) {
  .home .description-section .description div {
    width: 100%;
  }
}
.home .description-section .description span {
  font-weight: 300;
}
@media screen and (max-width: 959px) {
  .home .description-section .description span {
    font-size: 14px;
  }
}
.home .description-section .description p {
  font-weight: 400;
}
@media screen and (max-width: 959px) {
  .home .description-section .description p {
    font-size: 14px;
  }
}
.home .description-section .description a {
  margin-top: 60px;
  border-radius: 26px;
  border: none;
  background-color: #000;
  color: #FFF;
  font-weight: 500;
  font-family: Poppins;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 264px;
  height: 52px;
  cursor: pointer;
  text-decoration: none;
}
@media screen and (max-width: 959px) {
  .home .description-section .description a {
    margin-top: 30px;
    margin-bottom: 30px;
    height: 42px;
    font-size: 12px;
  }
}
.home .description-section .description a svg {
  width: 25.183px;
  height: 28.727px;
}
.home .description-section .description-icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home .description-section .description-icon #container {
  width: 500px;
  z-index: 5;
}
@media screen and (max-width: 959px) {
  .home .description-section .description-icon #container {
    width: 90%;
    margin: 1.5rem 0;
  }
}
.home .description-section .description-icon #container-overlay {
  position: absolute;
  top: 0.5;
  right: 1;
}
.home .description-section .description-icon #traveux {
  width: 350px;
  margin-top: 20px;
  margin-right: 22.5px;
}
.home .services-section {
  display: flex;
  justify-content: space-between;
  margin-top: 6rem;
}
@media screen and (max-width: 959px) {
  .home .services-section {
    flex-direction: column;
    gap: 15px;
    margin: 0 auto;
    width: 95%;
  }
}
.home .services-section #service1 {
  background: linear-gradient(177deg, #FC0404 3.6%, #5B0808 97.4%);
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.11);
  cursor: pointer;
}
.home .services-section #service2 {
  background: linear-gradient(177deg, #D7D7D7 6.07%, rgba(215, 215, 215, 0) 97.67%);
  box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.06);
  color: #000;
  height: 600px;
  cursor: pointer;
}
@media screen and (max-width: 959px) {
  .home .services-section #service2 {
    height: auto;
    padding-bottom: 35.5px;
  }
}
.home .services-section #service3 {
  background: linear-gradient(177deg, #E10000 3.6%, #DA7B7B 97.4%);
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.11);
  cursor: pointer;
}
.home .services-section .service-section {
  width: 30%;
  height: 450px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 25px;
  padding-top: 35.5px;
  color: #FFF;
  padding-bottom: 5px;
}
@media screen and (max-width: 959px) {
  .home .services-section .service-section {
    width: 100%;
    padding-bottom: 35.5px;
    height: auto;
  }
}
.home .services-section .service-section .header {
  display: flex;
  width: 80%;
}
.home .services-section .service-section .header h2 {
  font-size: 28px;
  font-weight: 600;
  line-height: 44px;
}
@media screen and (max-width: 959px) {
  .home .services-section .service-section .header h2 {
    font-size: 22px;
  }
}
.home .services-section .service-section p {
  width: 85%;
  font-size: 14px;
}
@media screen and (max-width: 959px) {
  .home .services-section .service-section p {
    font-size: 12px;
  }
}
.home .services-section .service-section img {
  width: 261px;
  height: 260px;
}
.home .aide {
  position: fixed;
  bottom: 15%;
  right: 5%;
  z-index: 9999;
}
.home .aide .content {
  border-radius: 142.5px;
  background: #F00;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 0.5rem 0 0rem !important;
  margin-left: auto;
}
@media screen and (max-width: 959px) {
  .home .aide .content {
    padding: 0 !important;
  }
}
.home .aide .content .white-round {
  width: 76px;
  height: 76px;
  background-color: #FFF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 4px;
}
.home .aide .content .text-content {
  display: flex;
  flex-direction: column;
  color: #FFF;
  margin-left: 20px;
}
.home .aide .content .text-content h3 {
  font-size: 18px;
  font-weight: 700;
  height: 21px;
  margin: 0;
  padding: 0;
}
.home .aide .content .text-content span {
  font-weight: 400;
  font-size: 14px;
  height: 21px;
  margin-top: 7px;
}
.home .publicites {
  background-image: url("../../images/monaco.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  display: flex;
  margin: 0px -25px 0px -61px;
}
@media screen and (max-width: 959px) {
  .home .publicites {
    margin: 0;
  }
}
.home .publicites .content {
  margin: 0px 0px 0px 61px;
  display: flex;
  margin-top: 39px;
  width: 100%;
  padding-bottom: 6rem;
}
@media screen and (max-width: 959px) {
  .home .publicites .content {
    flex-direction: column;
    margin: 0;
  }
}
.home .publicites .content .content-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
}
@media screen and (max-width: 959px) {
  .home .publicites .content .content-header {
    width: 95%;
    margin: 0 auto;
  }
}
.home .publicites .content .content-header h1 {
  font-size: 38px;
  font-weight: 600;
}
@media screen and (max-width: 959px) {
  .home .publicites .content .content-header h1 {
    font-size: 28px;
  }
}
.home .publicites .content .content-header span {
  margin-top: 20px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
}
@media screen and (max-width: 959px) {
  .home .publicites .content .content-header span {
    font-size: 12px;
  }
}
.home .publicites .content .skills {
  margin-left: auto;
  width: 80%;
}
@media screen and (max-width: 959px) {
  .home .publicites .content .skills {
    width: 100%;
    margin: 0;
  }
}
.home .publicites .content .skills #title {
  color: #fff;
  background-color: #F00;
  width: 800px;
}
@media screen and (max-width: 959px) {
  .home .publicites .content .skills #title {
    width: 95%;
    margin: 0 auto;
  }
}
.home .publicites .content .skills .skill {
  border-radius: 142.5px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  width: 754px;
  margin-left: auto;
  margin-top: 18px;
}
@media screen and (max-width: 959px) {
  .home .publicites .content .skills .skill {
    width: 85%;
    margin: 1rem 0 1rem auto;
  }
}
.home .publicites .content .skills .skill .go-to {
  position: relative;
  display: flex;
  height: 45px;
  margin-top: 1.5rem;
  margin: 4px 5px;
}
.home .publicites .content .skills .skill .go-to svg {
  stroke-width: 2px;
  stroke: #FFF;
  flex-shrink: 0;
}
.home .publicites .content .skills .skill .go-to .container-overlay {
  position: absolute;
  display: flex;
  top: 0;
}
.home .publicites .content .skills .skill .go-to .container-overlay svg {
  flex-shrink: 0;
  padding-left: 5px;
  padding-top: 3px;
}
.home .publicites .content .skills .skill span {
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  color: #000;
}
@media screen and (max-width: 959px) {
  .home .publicites .content .skills .skill span {
    font-size: 12px;
  }
}
.home .go-to {
  position: relative;
  display: flex;
  height: 45px;
  margin-top: 1.5rem;
}
.home .go-to svg {
  stroke-width: 2px;
  stroke: #FFF;
  flex-shrink: 0;
}
.home .go-to .container-overlay {
  position: absolute;
  display: flex;
  top: 0;
}
.home .go-to .container-overlay svg {
  flex-shrink: 0;
  padding-left: 5px;
  padding-top: 3px;
}