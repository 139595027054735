.aboutUs {
  color: #000;
}
.aboutUs .description {
  background-image: url("../../images/background/background1.png");
}
.aboutUs .description .description-content {
  padding-top: 67px;
  color: #FFF;
  font-family: Poppins;
  font-style: normal;
  line-height: normal;
  width: 55%;
}
@media screen and (max-width: 959px) {
  .aboutUs .description .description-content {
    width: 100%;
  }
}
.aboutUs .description .description-content .description-title {
  background-color: #F00;
  border-radius: 142.5px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  width: 100%;
}
.aboutUs .description .description-content .description-title h1 {
  padding-top: 22px;
  padding-bottom: 25px;
  padding-left: 50px;
  font-size: 28px;
  font-weight: 600;
  width: 80%;
}
@media screen and (max-width: 959px) {
  .aboutUs .description .description-content .description-title h1 {
    font-size: 22px;
  }
}
.aboutUs .description .description-content .description-text {
  margin-top: 53px;
  padding-left: 50px;
  width: 90%;
}
@media screen and (max-width: 959px) {
  .aboutUs .description .description-content .description-text {
    width: 95%;
    padding: 0;
    margin: 0 auto;
  }
}
.aboutUs .description .description-content .description-text span {
  color: #000;
  font-size: 21px;
  font-weight: 400;
}
@media screen and (max-width: 959px) {
  .aboutUs .description .description-content .description-text span {
    font-size: 14px;
  }
}
.aboutUs .expertise {
  display: block;
  padding-left: 50px;
  margin-top: 119px;
  background: #F4F4F4;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 959px) {
  .aboutUs .expertise {
    padding: 0;
    padding-top: 1rem;
    margin: 2rem auto;
  }
}
.aboutUs .expertise .expertise-text {
  padding-top: 62px;
  width: 45%;
}
@media screen and (max-width: 959px) {
  .aboutUs .expertise .expertise-text {
    width: 95%;
    padding: 0;
    margin: 0 auto;
  }
}
.aboutUs .expertise .expertise-text h1 {
  font-size: 38px;
  font-weight: 600;
}
@media screen and (max-width: 959px) {
  .aboutUs .expertise .expertise-text h1 {
    font-size: 28px;
  }
}
.aboutUs .expertise .expertise-text span {
  font-size: 14px;
  font-weight: 400;
}
@media screen and (max-width: 959px) {
  .aboutUs .expertise .expertise-text span {
    font-size: 14px;
  }
}
.aboutUs .expertise .services {
  margin-top: 26px;
}
.aboutUs .expertise .services h2 {
  font-size: 24px;
  font-weight: 600;
}
.aboutUs .expertise .services #service1 {
  color: #fff;
  background-color: #F00;
  width: 100%;
  margin-left: 0;
}
.aboutUs .expertise .services .service {
  border-radius: 142.5px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  margin-left: 60px;
  margin-bottom: 16px;
}
.aboutUs .expertise .services .service .tick {
  position: relative;
  display: flex;
  height: 32px;
  margin-left: 4px;
  margin-top: 5px;
  margin-bottom: 4px;
}
.aboutUs .expertise .services .service .tick svg {
  stroke-width: 2px;
  stroke: #FFF;
  flex-shrink: 0;
}
.aboutUs .expertise .services .service .tick .container-overlay {
  position: absolute;
  display: flex;
  top: 0;
}
.aboutUs .expertise .services .service .tick .container-overlay svg {
  flex-shrink: 0;
  padding-left: 6px;
  padding-top: 6px;
}
.aboutUs .expertise .services .service span {
  font-size: 14px;
  font-weight: 500;
  margin-left: 30px;
  color: #000;
}
@media screen and (max-width: 959px) {
  .aboutUs .expertise .services .service span {
    margin-left: 10px;
  }
}
.aboutUs .expertise .services:last-child {
  padding-bottom: 75px;
}
.aboutUs .besoin-aide {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.aboutUs .besoin-aide .aide-text {
  width: 65%;
}
@media screen and (max-width: 959px) {
  .aboutUs .besoin-aide .aide-text {
    width: 95%;
    margin: 1rem auto;
  }
}
.aboutUs .besoin-aide .aide-text h2 {
  font-size: 38px;
  font-weight: 600;
}
@media screen and (max-width: 959px) {
  .aboutUs .besoin-aide .aide-text h2 {
    font-size: 28px;
  }
}
.aboutUs .besoin-aide .aide-text span {
  font-weight: 300;
  font-size: 18px;
}
@media screen and (max-width: 959px) {
  .aboutUs .besoin-aide .aide-text span {
    font-size: 14px;
  }
}
.aboutUs .besoin-aide .aide {
  position: fixed;
  bottom: 15%;
  right: 5%;
  z-index: 9999;
}
.aboutUs .besoin-aide .aide .content {
  border-radius: 142.5px;
  background: #F00;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 0.5rem 0 0rem !important;
  margin-left: auto;
}
@media screen and (max-width: 959px) {
  .aboutUs .besoin-aide .aide .content {
    padding: 0 !important;
  }
}
.aboutUs .besoin-aide .aide .content .white-round {
  width: 76px;
  height: 76px;
  background-color: #FFF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 4px;
}
.aboutUs .besoin-aide .aide .content .text-content {
  display: flex;
  flex-direction: column;
  color: #FFF;
  margin-left: 20px;
}
.aboutUs .besoin-aide .aide .content .text-content h3 {
  font-size: 18px;
  font-weight: 700;
  height: 21px;
  margin: 0;
  padding: 0;
}
.aboutUs .besoin-aide .aide .content .text-content span {
  font-weight: 400;
  font-size: 14px;
  height: 21px;
  margin-top: 7px;
}
.aboutUs .engagements {
  background-color: #000;
  color: #fff;
  padding-left: 50px;
}
@media screen and (max-width: 959px) {
  .aboutUs .engagements {
    padding: 0 0.5rem;
  }
}
.aboutUs .engagements div {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 51px;
  padding-top: 37px;
  padding-bottom: 83px;
}
.aboutUs .engagements div h2 {
  font-size: 38px;
  font-weight: 600;
}
@media screen and (max-width: 959px) {
  .aboutUs .engagements div h2 {
    font-size: 28px;
  }
}
.aboutUs .engagements div span {
  font-weight: 300;
  font-size: 14px;
}
@media screen and (max-width: 959px) {
  .aboutUs .engagements div span {
    font-size: 14px;
  }
}
.aboutUs .solutions {
  background-color: #fff;
  padding-left: 50px;
}
@media screen and (max-width: 959px) {
  .aboutUs .solutions {
    padding: 0 0.5rem;
  }
}
.aboutUs .solutions div {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding-bottom: 50px;
  width: 100%;
}
.aboutUs .solutions div h2 {
  font-size: 38px;
  font-weight: 600;
}
@media screen and (max-width: 959px) {
  .aboutUs .solutions div h2 {
    font-size: 28px;
  }
}
.aboutUs .solutions div span {
  font-weight: 300;
  font-size: 18px;
}
@media screen and (max-width: 959px) {
  .aboutUs .solutions div span {
    font-size: 14px;
  }
}