.contact {
  margin-left: 60px;
  margin-top: 68px;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 959px) {
  .contact {
    flex-direction: column;
    width: 95%;
    margin: 0 auto;
  }
}
.contact .contact-form {
  width: 45%;
  display: block;
  margin-bottom: 76px;
}
@media screen and (max-width: 959px) {
  .contact .contact-form {
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 5px;
  }
}
.contact .contact-form .contact-form-header {
  color: black;
  color: var(--black, #000);
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
@media screen and (max-width: 959px) {
  .contact .contact-form .contact-form-header {
    font-size: 24px;
  }
}
.contact .contact-form .contact-form-header span {
  color: #F00;
}
.contact .contact-form p {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.14px;
}
.contact .contact-form form {
  display: flex;
  flex-direction: column;
}
.contact .contact-form form input {
  margin: 20px 0px 0px 0px;
  padding: 12px 20px;
  border-radius: 55px;
  border: 1px solid var(--Gray-5, #E0E0E0);
}
.contact .contact-form form input .namePlaceholder span {
  color: red;
}
.contact .contact-form form input:first-child {
  margin: 36px 0px 0px 0px;
}
.contact .contact-form form textarea {
  border-radius: 11px;
  border: 1px solid rgba(130, 130, 130, 0.35);
  margin-top: 25px;
  padding: 20px;
  min-height: 140px;
}
.contact .contact-form form button {
  padding: 12px 24px;
  background-color: #F00;
  color: var(--white, #FFF);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border: none;
  border-radius: 55px;
  margin-top: 25px;
  cursor: pointer;
}
.contact .contact-form .contact-info {
  display: flex;
  margin-top: 33px;
}
@media screen and (max-width: 959px) {
  .contact .contact-form .contact-info {
    flex-direction: column;
    gap: 15px;
  }
}
.contact .contact-form .contact-info .info {
  display: flex;
  margin-right: 20px;
}
.contact .contact-form .contact-info .info:last-child {
  margin-right: 0;
}
.contact .contact-form .contact-info .info svg {
  margin-right: 15px;
}
.contact .contact-form .contact-info .info .text {
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.26px;
  width: 100%;
}
.contact .contact-form .contact-info .info .text span {
  width: 130%;
}
.contact .contact-form .contact-info .info .text span:first-child {
  color: black;
  font-weight: 600;
}
.contact .contact-form .contact-info .info .text span:last-child {
  color: #F00;
  font-weight: 400;
}
.contact .contact-form .contact-info .info .text .adresse {
  width: 250%;
}
.contact .contact-form .contact-info .info .text .adresse p {
  color: #F00;
  width: 100%;
  line-height: 7px;
  font-family: Montserrat;
  font-size: 13px;
}
.contact .contact-form .contact-info .info .icon-localisation {
  position: relative;
  display: flex;
}
.contact .contact-form .contact-info .info .icon-localisation svg {
  flex-shrink: 0;
}
.contact .contact-form .contact-info .info .icon-localisation .container-overlay {
  position: absolute;
  display: flex;
  top: 0;
}
.contact .contact-form .contact-info .info .icon-localisation .container-overlay svg {
  flex-shrink: 0;
  padding-left: 5px;
  padding-top: 5px;
}
.contact .contact-location {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: flex-end;
}
@media screen and (max-width: 959px) {
  .contact .contact-location {
    width: 100%;
  }
}
.contact .contact-location .location {
  display: flex;
  flex-direction: column;
  position: relative;
}
.contact .contact-location .location .google-maps-location {
  border-radius: 30px;
  border: none;
  z-index: 2;
  position: relative;
  margin-top: 50px;
  margin-right: 200px;
  top: 6%;
  width: 80%;
}
@media screen and (max-width: 959px) {
  .contact .contact-location .location .google-maps-location {
    width: 100%;
    height: 400px;
  }
}
.contact .contact-location .location .arrow {
  margin-left: auto;
  margin-right: 165px;
  width: auto;
  position: relative;
  z-index: 1;
  right: 5%;
  top: 5%;
}
.contact .contact-location .location .image-container {
  margin-left: auto;
  position: relative;
  z-index: 0;
  width: 300px;
  display: flex;
}
@media screen and (max-width: 959px) {
  .contact .contact-location .location .image-container {
    transform: translateX(10px);
  }
}
.contact .contact-location .location .image-container img {
  width: 300px;
}