.service3 {
  color: #000;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-image: url("../../../images/background/background2.png");
}
@media screen and (max-width: 959px) {
  .service3 {
    font-size: 14px;
  }
}
.service3 .service-header {
  display: flex;
  align-items: center;
  padding-bottom: 26.5px;
}
.service3 .service-header .service-description {
  display: flex;
  flex-direction: column;
  width: 52.5%;
  margin-left: 60px;
}
@media screen and (max-width: 959px) {
  .service3 .service-header .service-description {
    width: 95%;
    margin: 0 auto;
  }
}
.service3 .service-header .service-description h1 {
  font-size: 38px;
  font-weight: 600;
}
@media screen and (max-width: 959px) {
  .service3 .service-header .service-description h1 {
    font-size: 28px;
  }
}
.service3 .service-header .service-description span {
  color: #f00;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 13px;
}
@media screen and (max-width: 959px) {
  .service3 .service-header .service-description span {
    font-size: 15px;
  }
}
.service3 .service-header .service-description p {
  width: 70%;
  font-size: 14px;
  font-weight: 400;
  margin-block-end: 0;
}
@media screen and (max-width: 959px) {
  .service3 .service-header .service-description p {
    width: 100%;
    font-size: 12px;
  }
}
.service3 .service-header .aide {
  position: fixed;
  bottom: 15%;
  right: 5%;
  z-index: 9999;
  z-index: 9999;
}
.service3 .service-header .aide .content {
  border-radius: 142.5px;
  background: #F00;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 0.5rem 0 0rem !important;
  margin-left: auto;
}
@media screen and (max-width: 959px) {
  .service3 .service-header .aide .content {
    padding: 0 !important;
  }
}
.service3 .service-header .aide .content .white-round {
  width: 76px;
  height: 76px;
  background-color: #FFF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 4px;
}
.service3 .service-header .aide .content .text-content {
  display: flex;
  flex-direction: column;
  color: #FFF;
  margin-left: 20px;
}
.service3 .service-header .aide .content .text-content h3 {
  font-size: 18px;
  font-weight: 700;
  height: 21px;
  margin: 0;
  padding: 0;
}
.service3 .service-header .aide .content .text-content span {
  font-weight: 400;
  font-size: 14px;
  height: 21px;
  margin-top: 7px;
}
.service3 .gestion-technique {
  display: flex;
  align-items: center;
  margin-left: auto;
  background-color: #F1F1F1;
  padding-top: 30.5px;
  padding-bottom: 26px;
}
@media screen and (max-width: 959px) {
  .service3 .gestion-technique {
    flex-direction: column;
  }
}
.service3 .gestion-technique .gestion-technique-card {
  display: flex;
  background-color: #FFF;
  border-radius: 159.5px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.07);
  width: 55%;
  padding: 5px 0px;
  margin-right: 80px;
}
@media screen and (max-width: 959px) {
  .service3 .gestion-technique .gestion-technique-card {
    width: 100%;
    margin-right: 30px;
  }
}
.service3 .gestion-technique .gestion-technique-card img {
  margin-left: auto;
  margin-right: 20px;
}
.service3 .gestion-technique .gestion-technique-text {
  display: flex;
  flex-direction: column;
  width: 45%;
  margin-right: 93px;
}
@media screen and (max-width: 959px) {
  .service3 .gestion-technique .gestion-technique-text {
    width: 95%;
    margin: 0 auto;
  }
}
.service3 .gestion-technique .gestion-technique-text h3 {
  color: #F00;
  font-size: 18px;
  font-weight: 500;
}
.service3 .gestion-technique .gestion-technique-text .content {
  display: flex;
}
.service3 .gestion-technique .gestion-technique-text .content div {
  margin-right: 26px;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 959px) {
  .service3 .gestion-technique .gestion-technique-text .content div {
    margin-right: 10px;
  }
}
.service3 .gestion-technique .gestion-technique-text .content div span {
  padding-bottom: 20px;
  font-size: 17px;
  font-weight: 400;
}
@media screen and (max-width: 959px) {
  .service3 .gestion-technique .gestion-technique-text .content div span {
    font-size: 14px;
  }
}
.service3 .gestion-audits {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-top: 30.5px;
  padding-bottom: 80px;
}
@media screen and (max-width: 959px) {
  .service3 .gestion-audits {
    flex-direction: column-reverse;
  }
}
.service3 .gestion-audits .gestion-audits-card {
  display: flex;
  background-color: #FFF;
  border-radius: 159.5px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.07);
  width: 55%;
  padding: 5px 0px;
  margin-left: 80px;
  margin-right: auto;
}
@media screen and (max-width: 959px) {
  .service3 .gestion-audits .gestion-audits-card {
    width: 95%;
    margin-left: auto;
    margin-right: none;
  }
}
.service3 .gestion-audits .gestion-audits-card img {
  margin-right: auto;
  margin-left: 20px;
}
.service3 .gestion-audits .gestion-audits-text {
  display: flex;
  flex-direction: column;
  width: 45%;
  margin-right: 80px;
  margin-left: 60px;
}
@media screen and (max-width: 959px) {
  .service3 .gestion-audits .gestion-audits-text {
    width: 95%;
    margin: 0 auto;
  }
}
.service3 .gestion-audits .gestion-audits-text h3 {
  color: #F00;
  font-size: 18px;
  font-weight: 500;
}
.service3 .gestion-audits .gestion-audits-text .content .content-style {
  display: block;
  margin-left: 40px;
  width: 100%;
}
@media screen and (max-width: 959px) {
  .service3 .gestion-audits .gestion-audits-text .content .content-style {
    margin-left: 10px;
  }
}
.service3 .gestion-audits .gestion-audits-text .content .content-style h3 {
  color: #000;
}
.service3 .gestion-audits .gestion-audits-text .content .content-style span {
  margin-left: 20px;
}
@media screen and (max-width: 959px) {
  .service3 .gestion-audits .gestion-audits-text .content .content-style span {
    font-size: 14px;
  }
}
.service3 .outro {
  display: flex;
  align-items: baseline;
  width: 100%;
  margin-bottom: 57px;
  background: #FFF;
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.07);
  gap: 45px;
}
@media screen and (max-width: 959px) {
  .service3 .outro {
    gap: 20px;
    flex-direction: column;
  }
}
.service3 .outro .outro-text {
  width: 538px;
  margin-left: 60px;
  padding-bottom: 59px;
}
@media screen and (max-width: 959px) {
  .service3 .outro .outro-text {
    padding-bottom: 20px;
    width: 95%;
    margin: 1rem auto;
  }
}
.service3 .outro .outro-text span {
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
}
@media screen and (max-width: 959px) {
  .service3 .outro .outro-text span {
    font-size: 13px;
  }
}
.service3 .outro .outro-style {
  margin-left: auto;
  width: 55%;
  padding-top: 60px;
}
@media screen and (max-width: 959px) {
  .service3 .outro .outro-style {
    width: 100%;
    padding-top: 0;
  }
}
.service3 .outro .outro-style .services #service1 {
  color: #fff;
  background-color: #F00;
  width: 100%;
  margin-left: 0;
}
.service3 .outro .outro-style .services .service {
  border-radius: 142.5px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  margin-left: 60px;
  margin-bottom: 27px;
}
.service3 .outro .outro-style .services .service .tick {
  position: relative;
  display: flex;
  height: 32px;
  margin-left: 4px;
  margin-top: 5px;
  margin-bottom: 4px;
}
.service3 .outro .outro-style .services .service .tick svg {
  stroke-width: 2px;
  stroke: #FFF;
  flex-shrink: 0;
}
.service3 .outro .outro-style .services .service .tick .container-overlay {
  position: absolute;
  display: flex;
  top: 0;
}
.service3 .outro .outro-style .services .service .tick .container-overlay svg {
  flex-shrink: 0;
  padding-left: 6px;
  padding-top: 6px;
}
.service3 .outro .outro-style .services .service span {
  font-size: 14px;
  font-weight: 500;
  margin-left: px;
  width: 100%;
  padding: 5px;
}
@media screen and (max-width: 959px) {
  .service3 .outro .outro-style .services .service span {
    font-size: 12px;
  }
}