@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");
.navbar {
  display: flex;
  flex-direction: column;
  width: auto;
  background-color: black;
}
.navbar .navbar-contact {
  display: flex;
  align-items: center;
  height: 45px;
  margin-left: auto;
  margin-right: 62px;
}
@media screen and (max-width: 959px) {
  .navbar .navbar-contact {
    flex-direction: column;
    height: 100%;
    padding: 1rem 0.5rem;
    gap: 10px;
    margin: 0;
    justify-content: space-between;
  }
}
.navbar .navbar-contact span {
  display: flex;
  align-items: center;
  margin-right: 21px;
  font-weight: 200;
}
@media screen and (max-width: 959px) {
  .navbar .navbar-contact span {
    font-size: 14px;
    flex-direction: column;
  }
}
.navbar .navbar-contact span svg {
  margin-right: 6px;
}
.navbar .navbar-contact .credentials {
  background-color: #F00;
  border-radius: 17px;
  border: 1px solid rgba(255, 0, 0, 0.58);
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 35px;
}
.navbar .navbar-contact .credentials span {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
}
.navbar .navbar-contact .credentials span:first-child {
  margin-left: 19.4px;
}
.navbar .navbar-contact .credentials span:first-child svg {
  margin-right: 3.04px;
}
.navbar .navbar-contact .credentials span:last-child {
  margin-left: 13px;
}
.navbar .navbar-contact .credentials span:last-child svg {
  margin-right: 7px;
  color: #FFF !important;
}
.navbar .navbar-contact .credentials .vertical-line {
  background-color: rgba(255, 255, 255, 0.66);
  width: 1px;
  height: 35px;
  margin-left: 11px;
  border: 1px solid rgba(255, 255, 255, 0.66);
  border-left: none;
}
.navbar .navbar-content {
  display: flex;
  align-items: center;
  min-height: 124px;
  background: #FFF;
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  padding: 0 0.5rem;
}
@media screen and (max-width: 959px) {
  .navbar .navbar-content {
    min-height: 90px;
    justify-content: space-between;
  }
}
.navbar .navbar-content .bard {
  position: absolute;
  top: 100%;
  width: 87%;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.08);
}
.navbar .navbar-content .bard a {
  padding: 0.5rem;
  color: black;
}
.navbar .navbar-content img {
  width: 203px;
  height: 46px;
  flex-shrink: 0;
  margin-left: 61px;
}
@media screen and (max-width: 959px) {
  .navbar .navbar-content img {
    margin-left: 0;
  }
}
.navbar .navbar-content .links {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 62px;
  gap: 30px;
}
@media screen and (max-width: 959px) {
  .navbar .navbar-content .links {
    margin: 0;
  }
}
.navbar .navbar-content .links .btn-card {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #F00;
  border-radius: 5px;
  color: #F00;
}
.navbar .navbar-content .links .active {
  color: #F00;
  font-size: 14px;
  font-weight: 700;
}
.navbar .navbar-content .links a {
  text-decoration: none;
  color: black;
  margin-right: 38px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.navbar .navbar-content .links a .contact-button {
  border-radius: 17px;
  border: 1px solid #F00;
  display: flex;
  align-items: center;
  padding: 6px 25px 6px 30px;
  color: white;
  background-color: #F00;
  gap: 10px;
}
.navbar .navbar-content .links a .contact-button svg {
  margin-left: 1px;
}
.navbar .navbar-content .links a .contact-button:hover {
  background-color: #F00;
  color: white;
  border: 1px solid #F00;
  transition: 0.7s;
}
.navbar .navbar-content .links a:hover {
  color: #F00;
  transition: 0.5s;
}
.navbar .navbar-content .links a:last-child {
  margin: 0;
}
.navbar .icon-localisation {
  position: relative;
  display: flex;
}
.navbar .icon-localisation svg {
  flex-shrink: 0;
}
.navbar .icon-localisation .container-overlay {
  position: absolute;
  display: flex;
  top: 0;
}
.navbar .icon-localisation .container-overlay svg {
  flex-shrink: 0;
  padding-left: 3px;
  padding-top: 3px;
}