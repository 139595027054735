@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");
.footer {
  background-color: black;
  width: auto;
  min-height: 199px;
}
.footer .content {
  padding-top: 29px;
  margin-left: 5%;
  margin-right: 5%;
}
.footer .content .main-footer {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 959px) {
  .footer .content .main-footer {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
}
.footer .content .main-footer img {
  margin-left: 1%;
  width: 203px;
  height: 46px;
  flex-shrink: 0;
}
.footer .content .main-footer .links {
  margin-left: 20%;
  display: flex;
  width: 100%;
}
@media screen and (max-width: 959px) {
  .footer .content .main-footer .links {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
  }
}
.footer .content .main-footer .links div {
  display: flex;
  flex-direction: column;
  margin-right: 5%;
}
.footer .content .main-footer .links div a {
  text-decoration: none;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 34px;
}
.footer .content .social-media {
  display: flex;
  align-items: center;
  width: 100%;
}
@media screen and (max-width: 959px) {
  .footer .content .social-media {
    flex-direction: column;
    align-items: flex-start;
  }
}
.footer .content .social-media span {
  color: rgba(235, 235, 235, 0.63);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media screen and (max-width: 959px) {
  .footer .content .social-media span {
    margin: 0.5rem 0;
  }
}
.footer .content .social-media .icons {
  display: flex;
  margin-left: auto;
  width: auto;
}
.footer .content .social-media .icons a {
  margin-right: 13px;
}
.footer .content .social-media .icons a:last-child {
  margin-right: 0;
}
.footer .content .horizontale-line {
  height: 0px;
  border-color: rgba(255, 255, 255, 0.28);
  border-top: none;
  margin-bottom: 12px;
}
.footer .content .copyrights {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 959px) {
  .footer .content .copyrights {
    flex-direction: column-reverse;
  }
}
.footer .content .copyrights span {
  font-size: 16px;
}
@media screen and (max-width: 959px) {
  .footer .content .copyrights span {
    font-size: 12px;
    text-align: center;
  }
}
.footer .content .copyrights .coordonnes {
  display: flex;
  align-items: center;
  margin-left: auto;
}
@media screen and (max-width: 959px) {
  .footer .content .copyrights .coordonnes {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    width: 100%;
    padding: 0 0.5rem;
  }
}
.footer .content .copyrights .coordonnes span {
  border: 1px solid rgba(255, 0, 0, 0.58);
  background: #F00;
  border-radius: 17px;
  margin: 0px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 17px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media screen and (max-width: 959px) {
  .footer .content .copyrights .coordonnes span {
    padding: 0.5rem;
    margin: 0.5rem 0;
    width: 97%;
    justify-content: flex-start;
  }
}
.footer .content .copyrights .coordonnes span svg {
  margin-right: 7.27px;
}
.footer .content .copyrights .coordonnes span:last-child {
  margin: 0;
}
@media screen and (max-width: 959px) {
  .footer .content .copyrights .coordonnes span:last-child {
    margin: 0.5rem 0;
  }
}
.footer .icon-localisation {
  position: relative;
  display: flex;
}
.footer .icon-localisation svg {
  flex-shrink: 0;
}
.footer .icon-localisation .container-overlay {
  position: absolute;
  display: flex;
  top: 0;
}
.footer .icon-localisation .container-overlay svg {
  flex-shrink: 0;
  padding-left: 20%;
  padding-top: 20%;
}