.mobile-version{
    height:100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    div{
        width: 85%;
        
        .imagebox{
            margin: 0 auto;
            width: 150px;
            text-align: center;
            img{
                width: 100%;
                height: 100%;
            }
        }   
        .text-phone{
            text-align: center;
            color: black;
            color: var(--black, #000);
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal; 
        }
    }
}
